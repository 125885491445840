export enum BRAND {
    FLEXPAY = "FLEXPAY",
    UPLIFT = "UPLIFT"
}

export enum InsuranceType {
    cancellation= 'cancellation',
    travel='travel',
    bankruptcy= 'bankruptcy'
}

export enum TripTypes {
    none = '',
    hotel = 'hotel',
    flight = 'flight',
    cruise = 'cruise',
    package = 'package',
    car = 'car',
    other = 'other',
    fractional = 'fractional',
    membership = 'membership',
    add_ons = 'add_ons',
    insurance = 'insurance'
}

export enum AdminTypes {
    noAdmin = 'noAdmin',
    omniAdmin = 'omniAdmin',
    superAdmin = 'superAdmin',
    csAdmin = 'csAdmin',
    upliftAdmin = 'upliftAdmin',
    multiAdmin = 'multiAdmin',
    singleAdmin = 'singleAdmin'
}

export declare type Insurance = {
    types?: InsuranceType[];
    price?: number
}

export declare type Reservations = {
    insurance?: Insurance[];
    price?: number;
    traveler_ids?: number[];
}

export declare type HotelReservations = Reservations & {
    aggregator?: string;
    hotel_name: string;
    check_in: string;
    check_out: string;
    number_of_rooms?: number;
    address?: SimpleAddress;
    supplier?: string;
    reservation_type?: string;
    property_code?: string;
    ticket_type?: 'digital' | 'post' | 'phone' | 'pick_up';
    brand?: string;
}

export declare type SimpleAddress = {
    street_address: string;
    city: string;
    postal_code: string;
    region: string;
    country: string
}

export declare type AirReservations = Reservations & {
    trip_type: 'oneway' | 'roundtrip' | 'open_jaw';
    pnr?: string;
    reservation_type?: 'standard' | 'pre_paid' | 'opaque';
    itinerary: AirItinerary[];
    origin?: string;
    destination?: string;
}

export declare type AirItinerary = {
    aggregator?: string;
    departure_apc?: string;
    departure_time: string;
    arrival_time: string;
    arrival_city?: string;
    ticket_type?: 'digital' | 'post' | 'phone' | 'pick_up';
    departure_city?: string;
    fare_class?: string;
    carrier_code?: string;
    supplier?: string;
}


export declare type CruiseReservations = Reservations & {
    aggregator?: string;
    cruise_line?: string;
    disembark_date: string;
    embark_date: string;
    itinerary: CruiseItinerary[];
    rooms?: Rooms[];
    supplier?: string;
    cruise_duration?: number;
    ship_code?: string;
    voyage_id?: string;
    brand?: string;
    add_ons?: AddOns[];
}

export declare type CruiseItinerary = {
    arrival_date: string;
    departure_date: string;
    arrival_port?: string;
    departure_port?: string;
}

export declare type Rooms = {
    cabin_type: string;
    state_room_type: string;
    state_room_number: string;
    deck_number: string;
    berthed_type: string;
    dining_service: string
}

export declare type AddOns = {
    name: string;
    price: number
}

export declare type Traveler = {
    id: number;
    first_name?: string;
    last_name?: string;
    date_of_birth?: string;
}

export declare type Localization = {
    currency: string;
    language: string;
    country: string;
}

export declare type AgentInfo = {
    id: string;
    agencyId?: string;
    email?: string;
    first_name?: string;
    family_name?: string;
    phone_number?: string;
}

export declare type BillingContact = {
    phone?: string;
    email?: string;
    first_name: string;
    last_name: string;
    // Only for the dev issues
    city: string;
    postal_code: string;
    region: string;
    street_address: string;
    date_of_birth: string;
}

export declare type MerchantData = {
    path: string;
}

export declare type Rentals = Reservations & {
    remarks: string;
    quantity: number;
    start_date: string;
    end_date: string;
    rental_items: Array<{
        id: string;
        name: string;
    }>
};

export declare type AddOn = {
    id?: string;
    name?: string;
    price?: string;
}

export declare type OrderLine = {
    quantity_unit: "string",
    name: "string",
    quantity: 1,
}

export declare type Agent = {
    email: string | null;
    agent_id: string | null;
    agent_roles?: Array<string>;
}

export declare type Recipient = {
    id: number
    first_name?: string;
    last_name?: string;
    date_of_birth?: string;
}

export declare type PassesSubscription = {
    name: string;
    type: string;
    renew?: boolean;
    upgraded?: boolean;
    purchase_site?: string;
    unit_price?: number;
    recipients: Recipient[],
    expire_date?: string;
    active_date?: string;
    quantity: number;
    renew_date?: string;
};

export declare type CampaignConfig = {
    name: string;
    code: string;
    order?: number;
}

export declare type Campaign = {
    code: string;
    order?: number;
}

export declare type RetailContext = {
    cruise?: CruiseContext;
    store?: StoreContext;
}

export declare type StoreContext = {
    name: string;
}

export declare type CruiseContext = {
    cruise_line: string;
    ship_code: string;
}

export declare type TripInfo = {
    localization: Localization;
    order_amount: number;
    agent: Agent;
    merchant_data: MerchantData;
    up_code: string;
    travelers?: Traveler[];
    hotel_reservations?: HotelReservations[];
    air_reservations?: AirReservations[];
    cruise_reservations?: CruiseReservations[];
    passes_subscription?: PassesSubscription[];
    billing_contact?: BillingContact;
    rentals?: Rentals[];
    retail_context?: RetailContext;
    order_lines?: OrderLine[];
    add_ons?: AddOn[];
    order_id?: string;
    merchant_field_1?: string;
    campaigns?: Campaign[];
    brand: BRAND;
}

export declare type OrderConfig = {
    order: TripInfo;
    estimates: {
        [key: string]: {
            price_type: string;
            value: number;
        }
    }
}

export declare type ButtonFlows = {
    showSendApplication: boolean,
    showQrCode: boolean,
    showStartApplication: boolean,
}

export declare type CustomConfig = {
    components?: Array<TripTypes>;
    'trip-options'?: {
        cruise: {
            'cruise-line': Array<[string, string]>
        }
    };
    'booking-window'?: {
        min: number,
        max: number,
    },
    dictionary?: any,
    filter?: {
        min: number,
        max: number,
        'trip-min': number
    },
    feature?: {
        subvention: boolean,
    },
    'subvention-default': boolean
    subventionCampaigns: Array<CampaignConfig>;
    isTokenizedPayment: boolean
    showSailAndSign: boolean
    hidePaymentConfirmation: boolean
    application?: {
        showAprToggle: boolean
    }
    overrideTitle: string
    onboardSpend: boolean
    onboardSpendCruiseLines: string[]
    onboardSpendInPortLocations: string[]
    storeNames: Array<[string, string]>;
    showQrcode: boolean;
    isWhiteLabel: boolean;
    buttonFlows: ButtonFlows
};

export enum EVENTS_CATEGORIES {
    eventCategorySys = "sys",
    eventCategoryUser = "user"
}

export enum EVENT_LABELS {
    eventLabelDidMount = "did-mount",
    eventLabelModalClose = "modal-close",
    eventLabelStepFinished = "step-finished",
    eventLabelDeviceIdentificationStart = "device-identification-start",
    eventLabelDeviceIdentificationEnd = "device-identification-end",
    eventLabelAlert = "alert"
}

export enum EVENT_ACTIONS {
    eventActionHandler = "handler",
    eventActionAuth = "auth",
    eventActionView = "view",
    eventActionBeforeUnload = "before-unload",
    eventActionModalView = "modal-view",
    eventActionCardView = "card-view",
    eventActionBlur = "blur",
    eventActionMouseup = "mouseup",
    eventActionPostResult = "post-result",
    eventActionIdentifyDevice = "identify-device",
}

export enum OnboardDeviceType {
    salesAgentDevice = "salesagent_device",
    personalDevice = "personal_device"
}

export enum OnboardDeviceIdentificationStatus {
    started = "started",
    completed = "completed"
}
