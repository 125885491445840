import { useCallback } from "react";
import { useParams } from "react-router-dom";

// This will handle order information when have to pass between views

/*
    We have two scenarios:
    1.- Passing order_id from application to application in progress
    2.- Passing order_id to edit an existing application from in progress

    This is meant to replace the existing system where we grab order id and tenant id from
    the url.
*/

interface Data {
    order_id?: string;
    tenant_id?: string;
}


export default function useParamsFromSessionStorage (): [Data, Function] {
    const { tenant_id }: any = useParams()

    const setDataWrapper = useCallback(({order_id}: Data) => {
        if (order_id) {
            sessionStorage.setItem('order_id', order_id);
        }
    }, []);

    return [{
        tenant_id,
        order_id: sessionStorage.getItem('order_id')!,
    }, setDataWrapper];
}
