import React from 'react';
import './pill.scss'

declare type ChipProps = {
    emailDomain: string;
    onClick: Function;
}

export default function Pill({ emailDomain, onClick } : ChipProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
    return (
        <div className={`pill`}>
            <div className="content">
                { emailDomain }
            </div>
            <button id="access-list-item"
                    type="button"
                    onClick={(e: any) => onClick(e)}
                    aria-label="Remove"
                    className="close">
                    <span aria-hidden="true">×</span>
            </button>
        </div>
    )
}
