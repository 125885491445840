import React from 'react';
import { EmptyResults as EmptyResultsImage } from '@images'
import { useTranslation } from 'react-i18next';
import './emptyResults.scss'

declare type EmptyResultProps = {
    title: string;
}

export default function EmptyResults({ title }: EmptyResultProps) {
    const { t } = useTranslation(['empty-results'])
    return (
        <div id="none-found" className="jumbotron jumbotron-fluid text-center">
            <div className="container">
                <h1>{title}</h1>
                <p>{t('modify-your-search')}</p>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-6">
                        { React.createElement(EmptyResultsImage, null, null) }
                    </div>
                </div>
            </div>
        </div>
    )
}
