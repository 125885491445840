import React from 'react';
import { useTranslation } from 'react-i18next';
import './loading.scss';


export default function Loading({ bigLoading = false }) {
    const { t } = useTranslation(['common'])
    if (bigLoading) return (
        <div id="load-processing" className="processing">
            <div role="alert" id="proc-e1593cf1-0868-4b2d-8313-ef5fec4cd86f" className="text-center">
                <h1 className="text-mayus">{t('processing.load')}</h1>
                <span className="loading-dots">
                    <span aria-hidden="true">•</span><span aria-hidden="true">•</span><span aria-hidden="true">•</span>
                </span>
            </div>
        </div>
    )

    return (
        <div id="uplift-transition-panel" className="dots-loading">
            <div className="table-cell">
                <span className="loading-dots">
                    <span>•</span><span>•</span><span>•</span></span>
                </div>
        </div>
    )
}
