import { GlobalContext } from '@contexts/Global';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Alert from "../Alert/alert";
import './applicationTip.scss';
import { APPLICATION_STATUSES } from '@helpers/Constants';


const STATUSES_TO_COLORS : { [key: string]: string } = {
    [APPLICATION_STATUSES.wait_for_client]: 'warning',
    [APPLICATION_STATUSES.offer_loaded]: 'warning',
    [APPLICATION_STATUSES.loan_approved]: 'warning',
    [APPLICATION_STATUSES.loan_reconfirm]: 'info',
    [APPLICATION_STATUSES.loan_review]: 'info',
}

export default function ApplicationTip() {
    const {
        globalData: {
            offerStatus,
            currentPartnerConfig
        }
    } = useContext(GlobalContext);
    const { t } = useTranslation(['application-tip']);
    const [transPrefix, setTransPrefix] = useState("");
    const [message, setMessage] = useState('');

    useEffect(() => {
        if (!currentPartnerConfig) return;

        setTransPrefix(currentPartnerConfig.onboardSpend ? "onboard-spend." : "");
    }, [currentPartnerConfig])

    useEffect(() => {
        const messagesPerStatus: any = {
            loan_review: t(`${transPrefix}messages.loan_review`),
            loan_reconfirm: t(`${transPrefix}messages.loan_review`),
        }

        if (offerStatus && messagesPerStatus[offerStatus]) {
            setMessage(messagesPerStatus[offerStatus])
        } else {
            setMessage(t(`${transPrefix}messages.default`));
        }
    }, [transPrefix, offerStatus, t]);

    return (
        <div className="row application-container">
            <div className={`col-12 px-0`}>
                <Alert color={ offerStatus in STATUSES_TO_COLORS ? STATUSES_TO_COLORS[offerStatus] : 'warning'}
                       iconAlignment="top">
                    { message }
                </Alert>
            </div>
        </div>
    )
}
