import LoginModal from '@pages/Auth/components/LoginModal/loginModal';
import React from 'react'
import "./footerModal.scss";

type FooterModalProps = {
    title: string,
    content: any,
    components: string[]
}

function FooterModal({ title, content, components }: FooterModalProps, ref: any) {
  return (
    <LoginModal
        showFooter={false}
        closable={true}
        title={title}
        content={content}
        onModalAcceptance={() => {}}
        components={components}
        ref={ref} />
  )
}

export default React.forwardRef(FooterModal);