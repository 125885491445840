import React, { useContext, useEffect, useState } from 'react';

import './tracker.scss';
import TrackerStep from './components/TrackerStep';
import { GlobalContext, GlobalData } from '@contexts/Global';
import { APPLICATION_STATUSES } from '@helpers/Constants';

export default function Tracker() {
    const { globalData: { offerStatus, currentPartnerConfig }, setGlobalData } = useContext(GlobalContext);
    const [stepsOnFail] = useState(['send', 'inProgress', 'applicationDesicion']);
    const [steps] = useState(['send', 'inProgress', 'applicationDesicion', 'clientDesicion', 'purchase']);

    const [stepNameToOfferStatus] = useState<any>({
        send_application: 0,
        wait_for_client: 1,
        offer_loaded: 1,
        loan_approved: 2,
        loan_review: 3,
        loan_reconfirm: 3,
        receive_payment: 4,
        trip_booked: 4,
        session_ended: 2,
        agent_ended: 2,
    });

    useEffect(() => {
        if (!offerStatus) return;
        const index = stepNameToOfferStatus[offerStatus];

        setGlobalData((gd: GlobalData) => ({
            ...gd,
            currentStep: index || 0
        }));
    }, [offerStatus, stepNameToOfferStatus, setGlobalData])

    if (currentPartnerConfig?.onboardSpend) return <></>
    if ([APPLICATION_STATUSES.agent_ended, APPLICATION_STATUSES.session_ended].includes(offerStatus)) return <></>

    return (
        <div className="tracker row">
            {
                !['agent_ended','session_ended'].includes(offerStatus || '')  &&
                steps.map((step, index) => {
                    return <TrackerStep step={step} className="col" index={index} name={step} key={step} />;
                })
            }

            {
                ['agent_ended','session_ended'].includes(offerStatus || '') &&
                stepsOnFail.map((step, index) => {
                    return <TrackerStep step={step} className="col-4" index={index} name={step} key={step} />;
                })
            }
        </div>
    )
}
