import React from "react";

import "./orderedList.scss"

declare type OrderedListProps = {
    children: React.ReactElement[]
};

export default function OrderedList({ children }: OrderedListProps) {

    return (<div className="ordered-list">
        {children.map((ele, index) => {
            return (<div className="list-item" data-index={index + 1} key={index}>
                <div className="content">{ele}</div>
            </div>)
        })}
    </div>)
}