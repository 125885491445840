import React, { useCallback, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@components';
import './leaveConfirmationModal.scss'
import useParamsFromSessionStorage from '@hooks/useParamsFromSessionStorage';
import useTabiService from '@hooks/useTabiService';
import { AuthenticationContext } from '@contexts/Authentication';
import { GlobalContext, GlobalData } from '@contexts/Global';
import { APPLICATION_STATUSES } from '@helpers/Constants';
import { addSessionTripInfo, todayPlusDays } from '@helpers/Utils';

function LeaveConfirmationModal({ handleUnblockNavigation }: any, ref: any) {
    const { t } = useTranslation(['leave-confirmation-modal', 'common']);
    const [{ order_id }] = useParamsFromSessionStorage();
    const { agentId } = useContext(AuthenticationContext);
    const tabiService = useTabiService();
    const leavingSession = useRef(false);
    const { setGlobalData, globalData } = useContext(GlobalContext);

    const handleLeaveSession = useCallback(async () => {
        // This prevents the user for triggering the event multiple times
        if (leavingSession.current) return;
        leavingSession.current = true

        if (order_id) {
            try {
                await tabiService.expireOrder(order_id, agentId)
            } catch(ex) {
                console.error('Error expiring order', ex)
            }
        }

        setGlobalData((gd: GlobalData) => {
            return {
                ...gd,
                offerId: null,
                offerStatus: APPLICATION_STATUSES.send_application
            }
        });

        // Save a copy of the application in session storage
        addSessionTripInfo({
            expiration: todayPlusDays(2),
            tripInfo: globalData.tripInfo,
            orderId: order_id
        });

        handleUnblockNavigation();
        leavingSession.current = false;
        ref.current?.hideModal(true)
    }, [order_id, tabiService, agentId, handleUnblockNavigation, ref, setGlobalData, globalData.tripInfo])

    return (
        <Modal id="confirm-leave" ref={ref} className="modal-booking-failed" name="confirm-leave"
            header={
                <h2 className="modal-title">
                    {t('title')}
                </h2>
            }
            body={
                <div className="modal-body-text">
                    <p>
                        {t('warning')}
                    </p>
                </div>
            }
            buttons={[{
                label: t('common:cancel'),
                className: 'btn btn-outline-primary',
                onClick: () => { ref.current?.hideModal(); }
            }, {
                label: t('common:confirm'),
                className: 'btn btn-primary',
                onClick: () => { handleLeaveSession();}
            }]}
        />
)

}

export default React.forwardRef(LeaveConfirmationModal);