import React, { useCallback, useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { GlobalContext, GlobalData } from '@contexts/Global';
import { addSessionTripInfo, todayPlusDays } from '@helpers/Utils';
import './sessionEnded.scss'
import useParamsFromSessionStorage from '@hooks/useParamsFromSessionStorage';
import { APPLICATION_STATUSES } from '@helpers/Constants';

export default function SessionEnded() {
    const { t } = useTranslation(['application-in-progress', 'common']);
    const history = useHistory();
    const [{ order_id, tenant_id }] = useParamsFromSessionStorage();
    const instructions: Array<string> = t('session-ended.paragraphs', { returnObjects: true });
    const { globalData: { tripInfo }, setGlobalData } = useContext(GlobalContext);

    const handleChangeBorrower = useCallback(() => {
        sessionStorage.setItem('order_id', order_id!);

        setGlobalData((gd: GlobalData) => {
            return {
                ...gd,
                offerId: null,
                offerStatus: APPLICATION_STATUSES.send_application
            }
        });

        history.push('/'+ tenant_id +'/application/');
    }, [history, tenant_id, order_id, setGlobalData]);

    const handleNewApplication = useCallback(() => {
        sessionStorage.removeItem('order_id');
        setGlobalData((gd: GlobalData) => {
            return {
                ...gd,
                offerId: null,
                offerStatus: APPLICATION_STATUSES.send_application
            }
        });
        history.push('/'+ tenant_id +'/application/')
    }, [tenant_id, history, setGlobalData]);


    useEffect(() => {
        if (!tripInfo) return;

        addSessionTripInfo({
            expiration: todayPlusDays(2),
            tripInfo,
            orderId: order_id
        });
    }, [tripInfo, order_id, setGlobalData]);

    useEffect(() => {
        setGlobalData((gd: GlobalData) => {
            return {
                ...gd,
                offerId: null
            }
        });

    }, [tripInfo, order_id, setGlobalData]);

    return (
        <div className="session-eneded row">
            <div className="col-12">
                <span className="title">
                    {t('session-ended.title')}
                </span>

                {
                    instructions.map((paragraph: string, index: number) => (
                        <p className="paragraph" key={index}>
                            {paragraph}
                        </p>
                    ))
                }

                <div className="buttons">
                    <button className="btn btn-outline-primary"
                            onClick={handleChangeBorrower}>
                        {t('session-ended.buttons.change-borrower')}
                    </button>
                    <button className="btn btn-primary"
                            onClick={handleNewApplication}>
                        {t('session-ended.buttons.new-application')}
                    </button>
                </div>
            </div>
        </div>
    )
}
