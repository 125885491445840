import { useEffect, useState } from 'react';
import axios from 'axios';
import { useSafeAxios } from '@hooks/useAxios';
import { TripInfo } from '@type';
import { getCid, useTracking } from './useTracking';

export default function useTabiService() {
    const { makeAxiosCall } = useSafeAxios();
    const [axiosApi] = useState(() => axios.create({
        baseURL: process.env.REACT_APP_TABI_API_BASE
    }));
    const { trackingReady } = useTracking();

    const [tabiApi, setTabiApi] = useState<any>(null);

    useEffect(() => {
        if (!axiosApi) return;
        if (!makeAxiosCall) return;
        if (!trackingReady) return;

        setTabiApi({
            partnerDetails() {
                return makeAxiosCall({
                    url: '/tabi/sa/v5/partner-details',
                    method: 'GET',
                }, axiosApi, 'fetch-partner-details', true, true, {}, (hasError: boolean, response: any) => {
                    if (!hasError) return {};

                    // The status code and text is returned inside the request
                    const { request: { status, statusText } } = response;

                    return {
                        error: response.message,
                        status,
                        statusText
                    };
                });
            },
            customConfig(upc: string) {
                return makeAxiosCall({
                    url: `/tabi/sa/v5/${upc}/ui`,
                    method: 'GET',
                }, axiosApi, 'custom-config');
            },
            createOffer(tripInfo: TripInfo) {
                return makeAxiosCall({
                    url: `/tabi/sa/v5/orders`,
                    method: 'POST',
                    data: { order_info: {customer_id: getCid(), ...tripInfo},  },
                }, axiosApi, 'create-order', true, true, {}, (hasError: boolean, response: any) => {
                    if (hasError) return {};

                    const { data: { order_id }} = response;

                    return { 'order-id': order_id };
                });
            },
            fecthTripInfo(orderId: string) {
                return makeAxiosCall({
                    url: `/tabi/sa/v5/orders/${orderId}/`,
                    method: 'GET',
                }, axiosApi, 'fetch-trip-info');
            },
            sendOffer(orderId: string, delivery_method: string | null) {
                return makeAxiosCall({
                    url: `/tabi/sa/v5/orders/${orderId}/offer`,
                    method: 'POST',
                    data: { delivery_method },
                }, axiosApi, 'send-offer', true, true, { 'order-id': orderId }, () => {
                    return { 'order-id': orderId};
                });
            },
            offerStatus(orderId: string) {
                return makeAxiosCall({
                    url: `/tabi/sa/v5/orders/${orderId}/status?stack=1`,
                    method: 'GET',
                }, axiosApi, 'status-poll', false);
            },
            fetchCard(orderId: string) {
                return makeAxiosCall({
                    url: `/tabi/sa/v5/orders/${orderId}/card`,
                    method: 'GET',
                }, axiosApi, 'retrieve-virtual-card');
            },
            fetchToken(orderId: string) {
                return makeAxiosCall({
                    url: `/tabi/sa/v5/orders/${orderId}/card`,
                    method: 'POST',
                    data: {}
                }, axiosApi, 'retrieve-virtual-card');
            },
            confirmOrder(orderId: string, confirmationId: string) {
                return makeAxiosCall({
                    url: `/tabi/sa/v5/orders/${orderId}/confirmation`,
                    method: 'POST',
                    data: {
                        confirmation_id: confirmationId
                    }
                }, axiosApi, 'send-confirmation', true, true);
            },
            expireOrder(orderId: string, agentId: string) {
                return makeAxiosCall({
                    url: `/tabi/sa/v5/orders/${orderId}/expire`,
                    method: 'POST',
                    data: {
                        agent_id: agentId,
                        code: 0
                        }
                }, axiosApi, 'expire-order');
            }
        });

    }, [axiosApi, makeAxiosCall, trackingReady])

    return tabiApi;
}


