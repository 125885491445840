import React, { ForwardedRef, MutableRefObject, ReactElement, useEffect, useRef, useState } from 'react';
import { on, off } from '@helpers/CustomEvents';
import { useTracking } from '@hooks/useTracking';
import { FlexPayMisc } from '@icons';

import './modal.scss';

declare type ModalProps = React.HTMLProps<string> & {
    buttons?: Array<{
        label: string,
        className?: string,
        id?: string,
        onClick: Function,
        disabled?: boolean
        loading?: boolean;
    }>,
    fullscreen?: boolean
    header?: ReactElement<any, any>,
    body?: ReactElement<any, any>,
    footer?: ReactElement<any, any>,
    neverClosable?: boolean;
    headerOnTop?: boolean;
    footerOnBottom?: boolean;
    onOpen?: Function;
    onClose?: Function;
    name: string;
};

function Modal({
    id, buttons, header, body, footer, className, name, neverClosable = false, headerOnTop = false,
    onOpen, onClose, fullscreen = false, footerOnBottom = false, ...props }: ModalProps,
    ref: ForwardedRef<any>
) {
    const [showModal, setShowModal] = useState(false);
    const { trackModal, trackModalClose } = useTracking();

    const atCloseCallback = useRef<any>();

    useEffect(() => {
        const handleModalClose = () => {
            setShowModal(false);
            trackModalClose && trackModalClose();
        }

        on(`modal:close-${id}`, handleModalClose);

        return () => {
            off(`modal:close-${id}`, handleModalClose);
        }
    }, [id, trackModalClose])

    useEffect(() => {
        const handleModalOpen = () => {
            setShowModal(true);
            trackModal(name);
        }

        on(`modal:open-${id}`, handleModalOpen);

        return () => {
            off(`modal:open-${id}`, handleModalOpen)
        }
    }, [id, trackModal, name])

    useEffect(() => {
        if (!ref) return;
        (ref as MutableRefObject<any>).current = {
            ...(ref as MutableRefObject<any>).current,
            showModal(atClose?: Function) {
                atCloseCallback.current = atClose;
                setShowModal(true);
                document.body.classList.add('modal-open');
                if (onOpen) setTimeout(() => onOpen(), 0);
                trackModal && trackModal(name);
            },
            hideModal(ignoreCallback = false) {
                setShowModal(false);
                if (onClose) onClose();
                if (!ignoreCallback) setTimeout(() => atCloseCallback.current && atCloseCallback.current(), 0);
                trackModalClose && trackModalClose(name);
                document.body.classList.remove('modal-open');
            },
            showing: false,
        };
    }, [ref, trackModalClose, trackModal, name, onClose, onOpen]);


    // useEffect(() => {
    //     if (showModal) document.body.classList.add('modal-open');
    //     if (!showModal) document.body.classList.remove('modal-open');
    // }, [showModal])

    return (
        <React.Fragment>
            <div className={`modal fade ${showModal ? 'show' : ''} ${className}`} id={id} aria-hidden={!showModal}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        {
                            !neverClosable &&
                            <button type="button"
                                    className="close"
                                    onClick={() => (ref as MutableRefObject<any>).current.hideModal()}
                                    data-dismiss={id}
                                    aria-label="Close">
                                { React.createElement(FlexPayMisc.Close) }
                            </button>
                        }
                        <div className="modal-header">
                            {
                                headerOnTop && header
                            }
                        </div>
                        <div className="modal-body">
                            {!headerOnTop && header}
                            {body}
                            {!footerOnBottom && footer}
                        </div>
                        <div className="modal-footer">
                            {footerOnBottom && footer}
                            {
                                buttons && buttons.map((button, index) =>
                                    <button type="button"
                                        className={button.className}
                                        onClick={() => button.onClick()}
                                        id={button.id}
                                        disabled={button.disabled}
                                        key={index}>
                                        {button.label}
                                        {button.loading && <span className="spinner-border spinner-border-sm"></span>}
                                    </button>
                                )

                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default React.forwardRef(Modal);