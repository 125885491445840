function on(eventType: string, listener : EventListener | Function) {
    document.addEventListener(eventType, listener as EventListener);
}

function off(eventType: string, listener: EventListener| Function) {
    document.removeEventListener(eventType, listener as EventListener);
}

function once(eventType: string, listener: EventListener| Function) {
    on(eventType, handleEventOnce);

    function handleEventOnce(event: any) {
        listener(event);
        off(eventType, handleEventOnce);
    }
}

function trigger(eventType: string, data: any) {
    const event = new CustomEvent(eventType, { detail: data });
    document.dispatchEvent(event);
}

export { on, once, off, trigger };