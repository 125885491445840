import React from 'react';
import { useTranslation } from 'react-i18next';

import { AdBlocker } from '@images';
import './UpBlocked.scss';

export default function UpBlocked() {
  const { t } = useTranslation(['uplib-blocked'])
  return (
    <div className="page-background-card card" id="up-blocked">
      <div className="card-body">
        <h4 className="title">
          {t('title')}
        </h4>
        <AdBlocker width={250} height={250} className="ad-blocker-image"/>
        <p className="details">
          {t('description-first')}
            <a href={t('support:email.link')}>{t('support:email.title')}</a>
          {t('description-second')}
        </p>
      </div>
    </div>
  )
}
