import React, { useState, useEffect, Suspense } from "react";
import { Route } from "react-router-dom";
import { Loading, UpBlocked } from '@components';

const MAX_UP_RETRIES = 10;

function UpSecureRoute({ component: Component, ...restOfProps } : any) {
  const [isLoading, setIsLoading] = useState(true);
  const [upLoaded, setUpLoaded] = useState(false);
  const [tries, setTries] = useState(0);

  useEffect(() => {
    if (tries < MAX_UP_RETRIES) {
      const upCheckerTimeoutId = setTimeout(() => setTries(t => window.up?.P ? MAX_UP_RETRIES: t+1), 500);

      return () => clearTimeout(upCheckerTimeoutId);
    } else {
      setIsLoading(false);
      setUpLoaded(typeof window.up?.P !== 'undefined')
    }
  }, [tries, setTries])

  if (isLoading) return <Loading bigLoading />
  if (!upLoaded) return <UpBlocked />

  return ( Component ?
    <Route
      {...restOfProps}
      render={(props) => <Suspense fallback={ <>Loading...</> }><Component {...props} /></Suspense>
      }
    /> : <Route {...restOfProps}>
      {restOfProps.children}
    </Route>
  );
}

export default UpSecureRoute;
