import React, { useContext, useState, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { AuthenticationContext } from '@contexts/Authentication';
import { Loading } from '@components';

function ProtectedRoute({ component: Component, ...restOfProps } : any) {
  const [isLoading, setIsLoading] = useState(true); 
  const [isAuthenticated, setIsAuthenticated] = useState(false); 
  const { getSession } = useContext(AuthenticationContext);

  useEffect(() => {
    const ensureLoggedIn = async () => {
      if (!getSession) return;

      try {
        const session = await getSession();
        setIsAuthenticated(session !== null);
      } catch(err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }

    ensureLoggedIn();

  }, [getSession]);
  
  if (isLoading) return <Loading bigLoading />



  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/sign-in" />
      }
    />
  );
}

export default ProtectedRoute;
