import { useState, useEffect } from 'react';
import axios from 'axios';
import { OrderConfig } from '@type';
import { useSafeAxios } from '@hooks/useAxios';

export default function useMarketingService() {
    const { makeAxiosCall } = useSafeAxios({ useIdToken: true, removeJWTHeader: true });
    const [api] = useState(() => axios.create({
        baseURL: process.env.REACT_APP_MARKETING_API_BASE
    }));
    const [marketingService, setMarketingService] = useState<any>(null);

    useEffect(() => {
        if (!api) return;
        if (!makeAxiosCall) return;
        setMarketingService({
            estimateOffer(orderConfig : OrderConfig) {
                return makeAxiosCall({
                    url: '/marketing/v6o/from',
                    method: 'POST',
                    data: orderConfig
                }, api, 'from-pricing', true, true);
            }
        })
    }, [api, makeAxiosCall]);

    return marketingService;
}


