import React from 'react';
import './chip.scss';

declare type ChipProps = {
    selected?: boolean | null,
    logo?: React.FunctionComponent<React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }>,
    text: string,
    disabled?: boolean,
}

export default function Chip({ selected = false, logo, text, onClick, className, title, disabled = false } : ChipProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
    return (
        <div className={`chip ${selected ? 'selected' : ''} ${className} ${disabled ? 'disabled' : ''}`} onClick={(e) => !disabled && onClick && onClick(e)} title={title}>
            { logo && React.createElement(logo, null, '') }
            <span> { text } </span>
        </div>
    )
}
