import { CognitoUserPool } from "amazon-cognito-identity-js";

const Storage = window.sessionStorage;

const poolData = {
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL || "",
    ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID || "",
    Storage,
}

export { Storage };

export default new CognitoUserPool(poolData);
