import { lazy, Suspense } from "react";
import {
  Switch,
  Route,
  Redirect,
  withRouter
} from "react-router-dom";

import ProtectedRoute from './components/ProtectedRoute';
import { useTracking } from '@hooks/useTracking';

import './styles/base.main.scss';


import { useEffect } from "react";
import { ThemeProvider } from "up-ops-web-shared-components";

const Auth = lazy(() => import(/* webpackChunkName: "auth" */'@pages/Auth/auth'));
const Home = lazy(() => import(/* webpackChunkName: "home" */'@pages/Home/home'));

function App() {
  const { trackPageMount, trackBeforeUnload, trackingReady } = useTracking();

  useEffect(() => {
    if (!trackPageMount) return;

    trackPageMount('agent-app');
  }, [trackPageMount]);

  useEffect(() => {
    if (!trackBeforeUnload) return;

    const handler = () => {
      trackBeforeUnload();
    }

    window.addEventListener('beforeunload', handler);

    return () => window.removeEventListener('beforeunload', handler);
  }, [trackBeforeUnload])

  if (!trackingReady) return (
    <div id="app"></div>
  )

  return (
    <div id="app">
      <ThemeProvider>
        <Suspense fallback={<>Loading...</>}>
          <Switch>
            <Route exact path="/sign-in" component={Auth} />
            <ProtectedRoute path="/" component={Home} />
            <Route exact path="*">
              <Redirect to="/" />
            </Route>
          </Switch>
        </Suspense>
      </ThemeProvider>
    </div>
  );
}

export default withRouter(App);
