import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@components';
import useParamsFromSessionStorage from '@hooks/useParamsFromSessionStorage';

function ModalBookingCompleted(props: any, ref: any) {
    const { t } = useTranslation(['application-in-progress']);
    const [{ tenant_id }] = useParamsFromSessionStorage();

    const handleFinishApplication = useCallback(() => {
        ref.current?.hideModal();
        sessionStorage.removeItem('order_id');
        setTimeout(() => window.location.href = `/${tenant_id}/application`, 100);
    }, [tenant_id, ref])

    return (
        <Modal id="test" ref={ref} className="modal-booking-completed" name="booking-completed"
                header={
                    <h2 className="modal-title">
                        {t('receive_payment.modals.congratulations.title')}
                    </h2>
                }
                body={
                    <p className="modal-body-text">
                        <span>
                            {t('receive_payment.modals.congratulations.body.start')}
                        </span>
                        <span style={{fontWeight: 'bold'}}>
                            {t('receive_payment.modals.congratulations.body.new-application')}
                        </span>
                        <span>
                            {t('receive_payment.modals.congratulations.body.end')}
                        </span>
                    </p>
                }
                buttons={[{
                    label: t('receive_payment.buttons.new-application'),
                    className: 'btn btn-primary',
                    id: "finish-application",
                    onClick: handleFinishApplication
                }]}
                />
    )
}

export default React.forwardRef(ModalBookingCompleted);
