import { applicationInProgressIcon } from '@icons';
import React, { useCallback } from 'react'

declare type CopyProps = {
    textToCopy: string
}

export default function Copy({ textToCopy } : CopyProps) {
    const CopyIcon = applicationInProgressIcon('copy');

    const handleOnCopy = useCallback(() => {
        navigator.clipboard.writeText(textToCopy);
    }, [textToCopy]);

    return (<button onClick={handleOnCopy} className="trigger-copy btn btn-link" title="Copy">
        { CopyIcon && React.createElement(CopyIcon, null, null, '') }
        </button>
    )
}
