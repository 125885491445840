import { GlobalContext } from '@contexts/Global';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { phonePreviewByOfferStatus } from '@images';
import './phonePreview.scss';

export default function PhonePreview() {
    const { t, i18n } = useTranslation(['phone-preview']);
    const { globalData } = useContext(GlobalContext);

    const previewImage = phonePreviewByOfferStatus(globalData.offerStatus || '', i18n.language);

    return (
        <div className="row phone-preview center-content">
            <div className="col-12 title">
                { t(globalData.currentPartnerConfig?.onboardSpend ? 'guest-sample' : 'client-sample') }
            </div>

            <div className="col-12">
                {previewImage && React.createElement(previewImage, null, '')}
            </div>
        </div>
    )
}
