import React, { useEffect } from 'react'
import { Modal } from '@components';
import JsBarcode from 'jsbarcode'

import './modalBarcodeFullscreen.scss';

function BarCodeComponent({cardString} : any) {
    useEffect(() => {
        if (!cardString) return;

        JsBarcode("#bigbarcode", cardString);
    }, [cardString]);

    return <canvas id="bigbarcode"></canvas>
}

function ModalBarcodeFullscreen(props: any, ref: any) {
    return (
        <Modal id="test" ref={ref} className="modal-barcode-fullscreen" name="barcode-fullscreen"
                body={
                    <BarCodeComponent cardString={props.cardString} />
                }
        />
    )
}

export default React.forwardRef(ModalBarcodeFullscreen);
