import { createRoot } from 'react-dom/client';
import { Authentication } from '@contexts/Authentication';
import { Global } from '@contexts/Global';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

import enUSTranslations from './translations/enUS.json';
import frCATranslations from './translations/frCA.json';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';

(window as any).BUILD = process.env.NODE_ENV;

i18next.init({
  interpolation: { escapeValue: false },
  resources: {
    'en': {
      ...enUSTranslations
    },
    'fr': {
      ...frCATranslations
    }
  },
  lng: "en",
  fallbackLng: "en",
  react: {
    transKeepBasicHtmlNodesFor: [
      'span',
      'div',
      'section',
      'br',
      'strong',
      'i',
      'p',
      'li',
      'ul',
      'table',
      'tbody',
      'tr',
      'th',
      'td',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'b',
      'a',
      'style',
      'em'
    ]
  }
})

const container = document.getElementById('main');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <BrowserRouter>
    <I18nextProvider i18n={i18next}>
      <Authentication>
        <Global>
          <App />
        </Global>
      </Authentication>
    </I18nextProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
