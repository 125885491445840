import React from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import { FlexPayMisc } from '@icons/index';

import './tooltip.scss';

interface Props {
    message: string;
    placement?: 'top' | 'right' | 'bottom' | 'left';
  }
  

const Tooltip : React.FC<Props> = ({ message, placement = 'top'}) => {
    const {
        getArrowProps,
        getTooltipProps,
        setTooltipRef,
        setTriggerRef,
        visible,
      } = usePopperTooltip({ placement });

    return (
        <>
            <div ref={setTriggerRef} className="tooltip-icon-container">
                <FlexPayMisc.InfoFlat width="23px" height="23px"/>
            </div>
            {visible && (
                <div ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container' })}>
                    <div {...getArrowProps({ className: 'tooltip-arrow' })} />
                    {message}
                </div>
            )}
        </>     
    );
}

export default Tooltip;
