import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { iconByTip } from '@icons';

import './footer.scss';
import { useTracking } from '@hooks/useTracking';
import FooterModal from '../FooterModal/footerModal';

import atou from '@documents/agentTerms.json';
import tou from '@documents/terms.json';
import pp from '@documents/privacy.json';

export default function Footer() {
    const { t, i18n } = useTranslation(['auth', 'common', 'support']);
    const { trackLangChange } = useTracking();

    // Modal states
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [components, setComponents] = useState([]);
    const footerModalRef = useRef<any>();

    const year = new Date().getFullYear()

    const handleChangeLang = useCallback((lang: string) => {
        if (!trackLangChange) return;

        i18n.changeLanguage(lang);
        trackLangChange([lang]);
    }, [i18n, trackLangChange]);

    const setModalDetails = useCallback(({title, content, components}: any) => {
        setTitle(title);
        setContent(content);
        setComponents(components);
    }, [setTitle, setContent, setComponents])

    const openModal = useCallback(({
        title,
        components,
        content
    }: any) => {

        setModalDetails({
            title,
            components,
            content
        })

        footerModalRef.current?.showModal()
      }, [footerModalRef, setModalDetails])

    return (
        <div id="footer" className="row justify-content-center">
            <div className="col-12 col-sm-11 footer-container">
                <div className="row">
                    <div className="col-sm-12 col-md-4">
                        <div className="languages-wrapper">
                            { React.createElement(iconByTip('world_green_outline'), null, '') }
                            <span className={`language-selector btn ${i18n.language === 'en' && 'selected'}`} onClick={() => handleChangeLang('en')}>
                                English
                            </span>
                            <span className={`language-selector btn ${i18n.language === 'fr' && 'selected'}`} onClick={() => handleChangeLang('fr')}>
                                Français
                            </span>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-8 links-container'>
                        <div className="footer-link">
                            <span tabIndex={0}>
                                <button tabIndex={0} onClick={() => openModal({
                            title: t('footer.atou.title'),
                            ...(atou as any)[i18n.language],
                        })}>
                                    {t('footer.atou.title')}
                                </button>
                            </span>
                        </div>
                        <div className="footer-link">
                            <span tabIndex={0}>
                                <button tabIndex={0} onClick={() => openModal({
                            title: t('footer.pp.title'),
                            ...(pp as any)[i18n.language],
                        })}>{t('footer.pp.title')}</button>
                            </span>
                        </div>
                        <div className="footer-link">
                            <span tabIndex={0}>
                                <button tabIndex={0} onClick={() => openModal({
                            title: t('footer.tou.title'),
                            ...(tou as any)[i18n.language],
                        })}>{t('footer.tou.title')}</button>
                            </span>
                        </div>
                    </div>
                </div>

                <div className="d-flex contact-container pt-4">
                    <span className='copyright-title'>{t('footer.upgrade.copyright')} {year} {t('footer.upgrade.title')} </span>

                    <div className='col contact-links-container'>
                        <div className="footer-contact-link">
                            <span  className="ml-1">
                                {t('support:email.label')}
                                <a href={t('support:email.link')} >{t('support:email.title')}</a>
                            </span>
                        </div>
                        <div className="footer-contact-link">
                            <span  className="ml-1">
                                {t('support:tel.agent.label')}
                                <a href={t('support:tel.agent.link')} >{t('support:tel.agent.title')}</a>
                            </span>
                        </div>
                        <div className="footer-contact-link">
                            <span  className="ml-1">
                                {t('support:tel.guest.label')}
                                <a href={t('support:tel.guest.link')} >{t('support:tel.guest.title')}</a>
                            </span>
                        </div>
                    </div>

                </div>
            </div>

            <FooterModal title={title} content={content} components={components} ref={footerModalRef}/>
        </div>
    )
}
