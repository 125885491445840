import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '@contexts/Global';
import { useTranslation } from 'react-i18next';
import './applicationState.scss'
import { APPLICATION_STATUSES } from '@helpers/Constants';
import ApplicationTip from '../ApplicationTip/applicationTip';

export default function ApplicationState() {
    const { globalData } = useContext(GlobalContext);
    const { t } = useTranslation(['application-in-progress', 'common']);
    const [pageHeader, setPageHeader] = useState("");
    const [instructionParagraphs, setInstructionParagraphs] = useState<Array<string>>([]);

    useEffect(() => {
        let prefix = globalData.currentPartnerConfig?.onboardSpend ? "onboard-spend." : "";

        const instructions: Array<string> = t(
            `${globalData.offerStatus}.${prefix}paragraphs`,
            {
                returnObjects: true, defaultValue: []
            }
        );

        setInstructionParagraphs(instructions);
        setPageHeader(t(`${globalData.offerStatus}.${prefix}header`));
    }, [globalData.offerStatus, t, globalData.currentPartner, globalData.currentPartnerConfig])

    return (
        <div className="application-state row">
            <div className="state-text-container col-12 col-md-7">
                <div className="header">
                    {pageHeader}
                </div>

                {
                    instructionParagraphs.map((paragraph: string, index: number) => (
                        <p className="paragraph" key={index}>
                                {paragraph}
                            </p>
                        ))
                }


                {
                    ![APPLICATION_STATUSES.trip_booked,
                        APPLICATION_STATUSES.receive_payment,
                        APPLICATION_STATUSES.session_ended,
                        APPLICATION_STATUSES.agent_ended].includes(globalData.offerStatus) && <ApplicationTip />
                }
            </div>
        </div>
    )
}
