// Countries
import { ReactComponent as CALogo } from './Flexpay/countries/CA.svg';
import { ReactComponent as USLogo } from './Flexpay/countries/US.svg';
import { ReactComponent as OtherCountryLogo } from './Flexpay/countries/Other.svg';

// Trips
import { ReactComponent as CarsLogo } from './Flexpay/trips/car.svg';
import { ReactComponent as CuriseLogo } from './Flexpay/trips/cruise.svg';
import { ReactComponent as FlightLogo } from './Flexpay/trips/flight.svg';
import { ReactComponent as FractionalLogo } from './Flexpay/trips/fractional.svg';
import { ReactComponent as HotelLogo } from './Flexpay/trips/hotel.svg';
import { ReactComponent as InsuranceLogo } from './Flexpay/trips/insurance.svg';
import { ReactComponent as MembershipLogo } from './Flexpay/trips/membership.svg';
import { ReactComponent as PackageLogo } from './Flexpay/trips/package.svg';
import { ReactComponent as OtherTripLogo } from './Flexpay/trips/other.svg';

// Helpful tips
import { ReactComponent as Calendar } from './Flexpay/tips/calendar.svg';
import { ReactComponent as Coin } from './Flexpay/tips/coin.svg';
import { ReactComponent as CreditScore } from './Flexpay/tips/credit_score.svg';
import { ReactComponent as Fee } from './Flexpay/tips/fee.svg';
import { ReactComponent as InterestRates } from './Flexpay/tips/interest_rates.svg';
import { ReactComponent as Timer } from './Flexpay/tips/timer.svg';
import { ReactComponent as NoAPR } from './Flexpay/tips/no-apr.svg';
import { ReactComponent as Play } from './Flexpay/tips/play.svg';
import { ReactComponent as Calculator } from './Flexpay/tips/calculator.svg';
import { ReactComponent as CalculatorPercent } from './Flexpay/tips/calculator-percent.svg';
import { ReactComponent as WorldGreen } from './Uplift/tips/world-green.svg';
import { ReactComponent as Sailing } from './Flexpay/tips/sailing.svg';
import { ReactComponent as Cart } from './Flexpay/tips/shopping-bag.svg';
import { ReactComponent as Percent } from './Flexpay/tips/qualified_customer.svg';
import { ReactComponent as CircleOne } from './Uplift/tips/numbers/one.svg';
import { ReactComponent as CircleTwo } from './Uplift/tips/numbers/two.svg';
import { ReactComponent as Application } from './Flexpay/tips/application.svg';
import { ReactComponent as WorldGreenOutline } from './Uplift/tips/world-green-outline.svg';
import { ReactComponent as Alert } from './Flexpay/tips/alert.svg';
import { ReactComponent as ShoppingBag } from './Flexpay/tips/shopping-bag.svg';
import { ReactComponent as AmountRequested } from './Flexpay/tips/amount-requested.svg';
import { ReactComponent as FlatInfo } from './Flexpay/tips/info.svg';

// Application Progress
import {ReactComponent as AgentIcon} from './Uplift/applicationInProgress/agent.svg';
import {ReactComponent as LinkIcon} from './Uplift/applicationInProgress/link.svg';
import {ReactComponent as ApplicationForm} from './Uplift/applicationInProgress/applicationForm.svg';
import {ReactComponent as CheckmarkIcon} from './Uplift/checkmark.svg';
import {ReactComponent as CopyIcon} from './Flexpay/applicationInProgress/copy.svg';
import { ReactComponent as LoanApprovedIcon } from './Uplift/applicationInProgress/loanApproved.svg';
import { ReactComponent as LoanDocumentationIcon } from './Uplift/applicationInProgress/loanDocumentation.svg';
import { ReactComponent as Circle } from './Flexpay/applicationInProgress/circle.svg';
import { ReactComponent as FinishIcon } from './Uplift/applicationInProgress/finish.svg';

// Misc
import {ReactComponent as WorldIcon} from './Uplift/world.svg';
import {ReactComponent as InfoIcon} from './Uplift/info-circle.svg';
import {ReactComponent as InfoIconFilled } from './Uplift/info-circle-filled.svg';
import {ReactComponent as WarningIcon } from './Uplift/warning-icon.svg';
import {ReactComponent as OpenInFullIcon } from './Uplift/open-in-full.svg';
import {ReactComponent as LinkStartApplicationIcon } from './Uplift/link.svg';
import {ReactComponent as Logo } from './Flexpay/flexpay-logo.svg';
import {ReactComponent as QRCodeIcon } from './Flexpay/qr-code.svg';
import {ReactComponent as UpgradeShield } from './Flexpay/upgrade-shield.svg';
import {ReactComponent as AlertFlat } from './Flexpay/alert-flat.svg';
import {ReactComponent as InfoFlat } from './Flexpay/info-flat.svg';
import {ReactComponent as Close } from './Flexpay/close.svg';

// White Label logos
import {ReactComponent as VailWhiteLabelLogo } from './Flexpay/epic-flexpay-logo.svg';

// Authentication
// import {ReactComponent as SecurityShield} from './Flexpay/auth/security-shield.png';

export const MAP_COUNTRY_CODES_AND_LOGOS: any = {
    US: USLogo,
    CA: CALogo,
    Other: OtherCountryLogo,
};

export const MAP_TRIPS_AND_LOGOS: any = {
    car: CarsLogo,
    cruise: CuriseLogo,
    flight: FlightLogo,
    fractional: FractionalLogo,
    hotel: HotelLogo,
    insurance: InsuranceLogo,
    membership: MembershipLogo,
    package: PackageLogo,
    other: OtherTripLogo
};

export const MAP_TIPS_AND_ICONS: any = {
    alert: Alert,
    calendar: Calendar,
    coin: Coin,
    credit_score: CreditScore,
    fee: Fee,
    interest_rates: InterestRates,
    timer: Timer,
    no_apr: NoAPR,
    play: Play,
    calculator: Calculator,
    calculator_percent: CalculatorPercent,
    world_green: WorldGreen,
    world_green_outline: WorldGreenOutline,
    sailing: Sailing,
    cart: Cart,
    percent: Percent,
    circle_1: CircleOne,
    circle_2: CircleTwo,
    application: Application,
    shopping_bag: ShoppingBag,
    amount_requested: AmountRequested,
    flat_info: FlatInfo
}

export const MAP_APPLICATION_IN_PROGRESS_ICONS: any = {
    link: LinkIcon,
    agent: AgentIcon,
    applicationForm: ApplicationForm,
    checkmark: CheckmarkIcon,
    circle: Circle,
    finishIcon: FinishIcon,
    copy: CopyIcon,
    states: {
        loan_approved: LoanApprovedIcon,
        loan_review: LoanDocumentationIcon,
        loan_reconfirm: LoanDocumentationIcon
    }
}

export const MAP_WHITE_LABEL_LOGOS: any = {
    "UP-17367120-50": VailWhiteLabelLogo,
    "UP-17367120-51": VailWhiteLabelLogo
}

export { WorldIcon, InfoIcon, InfoIconFilled, WarningIcon, OpenInFullIcon, LinkStartApplicationIcon, CheckmarkIcon, Logo, QRCodeIcon, UpgradeShield, AlertFlat, InfoFlat, Close };
