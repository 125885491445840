import {
    MAP_STEPS_AND_ICONS,
    MAP_APPLICATION_IN_PROGRESS_ICONS,
    WorldIcon,
    InfoIcon,
    InfoIconFilled,
    WarningIcon,
    OpenInFullIcon,
    LinkStartApplicationIcon,
    CheckmarkIcon,
    SecurityShield
} from "./UplfitTheme";

import {
    MAP_COUNTRY_CODES_AND_LOGOS as FLEXPAY_MAP_COUNTRY_CODES_AND_LOGOS,
    MAP_TRIPS_AND_LOGOS as FLEXPAY_MAP_TRIPS_AND_LOGOS,
    MAP_TIPS_AND_ICONS as FLEXPAY_MAP_TIPS_AND_ICONS,
    MAP_APPLICATION_IN_PROGRESS_ICONS as FLEXPAY_MAP_APPLICATION_IN_PROGRESS_ICONS,
    MAP_WHITE_LABEL_LOGOS,
    WorldIcon as FLEXPAY_WorldIcon,
    InfoIcon as FLEXPAY_InfoIcon,
    InfoIconFilled as FLEXPAY_InfoIconFilled,
    WarningIcon as FLEXPAY_WarningIcon,
    OpenInFullIcon as FLEXPAY_OpenInFullIcon,
    LinkStartApplicationIcon as FLEXPAY_LinkStartApplicationIcon,
    CheckmarkIcon as FLEXPAY_CheckmarkIcon,
    Logo as FlexpayLogo,
    QRCodeIcon,
    AlertFlat,
    InfoFlat,
    Close
} from "./FlexPayTheme";

export const logoByCountryCode = (countryCodes: string) =>
    {
        return FLEXPAY_MAP_COUNTRY_CODES_AND_LOGOS[countryCodes]
    }
export const logoByTrip = (trip: string)=>
    {
        return FLEXPAY_MAP_TRIPS_AND_LOGOS[trip]
    }
export const iconByTip = (tip: string)=>
    {
        return FLEXPAY_MAP_TIPS_AND_ICONS[tip];
    }
export const iconByStep = (step: string)=>
    {
        return MAP_STEPS_AND_ICONS[step];
    }
export const applicationInProgressIcon = (key: string)=>
    {
        return FLEXPAY_MAP_APPLICATION_IN_PROGRESS_ICONS[key];
    }
export const applicationStateIcon = (key: string)=>
    {
        return MAP_APPLICATION_IN_PROGRESS_ICONS.states[key];
    }

export const whiteLabelLogo = (upc: string) => {
    return MAP_WHITE_LABEL_LOGOS[upc];
}

const UpliftMisc = { CheckmarkIcon, WorldIcon, InfoIcon, InfoIconFilled, WarningIcon, OpenInFullIcon, LinkStartApplicationIcon, SecurityShield}
const FlexPayMisc = {FLEXPAY_CheckmarkIcon, FLEXPAY_WorldIcon, FLEXPAY_InfoIcon, FLEXPAY_InfoIconFilled, FLEXPAY_WarningIcon, FLEXPAY_OpenInFullIcon, FLEXPAY_LinkStartApplicationIcon, FlexpayLogo, QRCodeIcon, AlertFlat, InfoFlat, Close }
export { UpliftMisc, FlexPayMisc }