// Phone previews
import { ReactComponent as WaitingForClient } from './PhonePreviews/WaitingForClient.svg';
import { ReactComponent as OfferLoaded } from './PhonePreviews/OfferLoaded.svg';
import { ReactComponent as LoanApproved } from './PhonePreviews/LoanApproved.svg';
import { ReactComponent as LoanReview } from './PhonePreviews/LoanReview.svg';
import { ReactComponent as AdBlocker } from './adblock.svg';

import { ReactComponent as WaitingForClient_FR } from './PhonePreviews/WaitingForClient_FR.svg';
import { ReactComponent as OfferLoaded_FR } from './PhonePreviews/OfferLoaded_FR.svg';
import { ReactComponent as LoanApproved_FR } from './PhonePreviews/LoanApproved_FR.svg';
import { ReactComponent as LoanReview_FR } from './PhonePreviews/LoanReview_FR.svg';

// Misc
import { ReactComponent as EmptyResults } from './EmptyResults.svg';
import { ReactComponent as UniversalShutdown } from './UniversalShutdown.svg';

const MAP_OFFER_STATUS_TO_PHONE_PREVIEWS: any = {
    wait_for_client: WaitingForClient,
    offer_loaded: OfferLoaded,
    loan_approved: LoanApproved,
    loan_review: LoanReview,
    loan_reconfirm: LoanReview
};

const MAP_OFFER_STATUS_TO_PHONE_PREVIEWS_FR: any = {
    wait_for_client: WaitingForClient_FR,
    offer_loaded: OfferLoaded_FR,
    loan_approved: LoanApproved_FR,
    loan_review: LoanReview_FR,
    loan_reconfirm: LoanReview_FR
};

export const phonePreviewByOfferStatus = (status: string, language: string) => {
    if (language === 'en') return MAP_OFFER_STATUS_TO_PHONE_PREVIEWS[status];
    return MAP_OFFER_STATUS_TO_PHONE_PREVIEWS_FR[status];
}

export { EmptyResults, UniversalShutdown, AdBlocker };