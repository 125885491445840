import React, { useContext, useEffect, useRef } from 'react';
import { UniversalShutdown } from '@images';
import './globalShutdown.scss';
import { GlobalContext } from '@contexts/Global';
import { useTranslation } from 'react-i18next';
import { Footer, Modal } from '@components';
import { matchPath, useLocation } from 'react-router';
import useTabiService from '@hooks/useTabiService';
import { AuthenticationContext } from '@contexts/Authentication';

declare type GlobalShutdownProps = {
    modalMode?: boolean;
    showFooter?: boolean;
}

export default function GlobalShutdown({ modalMode = false, showFooter = true }: GlobalShutdownProps) {
    const {
        globalShutdown: {
            dictionary,
            universalShutdown
        }
    } = useContext(GlobalContext);

    const { agentEmail } = useContext(AuthenticationContext);

    const { pathname } = useLocation()

    const { i18n, t } = useTranslation(['common']);
    const tabiApi = useTabiService();
    const ref = useRef<any>();

    useEffect(() => {
        if (!modalMode) return;
        if (!tabiApi) return;

        if (universalShutdown) {
            ref.current.showModal();
            const { order_id } : any = matchPath(pathname, { path: '/:tenant_id/order/:order_id' })?.params ||
                             matchPath(pathname, { path: '/:tenant_id/application/:order_id' })?.params ||
                             {};

            if (order_id) {
                try {
                    tabiApi.expireOrder(order_id, agentEmail)
                } catch(e) {}
            }

        }

        if (!universalShutdown && ref.current.showing) ref.current.hideModal();
    }, [universalShutdown, modalMode, pathname, tabiApi, agentEmail])

    if (modalMode)
        return <Modal id="universal-shutdown" ref={ref} className="modal-universal-shutdown global-shutdown" name="universal-shutdown"
                        header={
                            <h2 className="modal-title">
                                {t('our-apologies')}
                            </h2>
                        }
                        body={
                            <div className="modal-body-text">
                                <UniversalShutdown />
                                <hr className="my-4" />
                                <div className="container">
                                    <div className="row justify-content-center mb-2 mt-3">
                                        <p>
                                            {dictionary[i18n.language !== 'en' ? 'fr' : 'en'].universalShutdownMsg}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        }
                        neverClosable
                        buttons={[]}
                    />

  return <div className="global-shutdown">
    <div className="container py-5">
        <div className="row justify-content-center mb-5">
            <div className="col-12 col-sm-10 col-md-8 col-lg-7">
                <h1>{t('our-apologies')}</h1>
                <UniversalShutdown />
                <hr className="my-4" />
                <div>
                    <p>
                        {dictionary[i18n.language !== 'en' ? 'fr' : 'en'].universalShutdownMsg}
                    </p>
                </div>

            </div>
        </div>
                {
                    showFooter &&
                    <Footer />
                }
    </div>

  </div>;
}
