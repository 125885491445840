import React from 'react';
import { FlexPayMisc, UpliftMisc } from '@icons';
import './alert.scss';

interface PaymentAlertProps {
    color: string;
    children?: React.ReactNode;
    iconAlignment?: 'top' | 'middle' | 'bottom';
    className?: string;
}

const Alert: React.FC<PaymentAlertProps> = ({ color, children, iconAlignment = 'middle', className = "" }) => {

    const Icon = color === 'success' ? UpliftMisc.CheckmarkIcon : FlexPayMisc.AlertFlat;

    return (
        <div className={`alert ${color} ${className}`}>
            <span className={`icon-container ${iconAlignment}`}>
                {React.createElement(Icon)}
            </span>
            <span>{children}</span>
        </div>
    );
};

export default Alert;