import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Modal } from '@components';
import { GlobalContext } from '@contexts/Global';
import { PartnerTypes } from '@helpers/Constants';

function ModalBookingFailed(props: any, ref: any) {
    const { t } = useTranslation(['application-in-progress']);
    const { globalData: { currentPartner, currentPartnerConfig } } = useContext(GlobalContext)
    const prefix = currentPartnerConfig?.onboardSpend ? "onboard-application." : "";
    const partnerTypePrefix = currentPartner?.industry === PartnerTypes.NonTravel ? 'non-travel' : 'travel'
    const plurals = {
        confirmationCopy: t(`receive_payment.confirmation-number.${partnerTypePrefix}`).toLowerCase(),
        confirmationCopyPlural: t(`receive_payment.confirmation-number.${partnerTypePrefix}-plural`).toLowerCase()
    }

    return (
        <Modal id="test" ref={ref} className="modal-booking-failed" name="booking-failed"
                header={
                    <h2 className="modal-title">
                        {t(`receive_payment.${prefix}modals.please-enter-confirmations.title`)}
                    </h2>
                }
                body={
                    <div className="modal-body-text">
                        <p>
                            <Trans
                                t={t}
                                i18nKey={`receive_payment.${prefix}modals.please-enter-confirmations.body`}
                            />
                        </p>
                        {
                            !currentPartnerConfig?.onboardSpend &&
                            <p>
                                <span>
                                    {t('receive_payment.one-confirmation-per-box', plurals)}
                                </span>&nbsp;
                                <span style={{fontWeight: 'bold'}}>
                                    {t('receive_payment.add-more')}.
                                </span>
                            </p>
                        }

                    </div>
                }
                buttons={[{
                    label: t('receive_payment.buttons.back'),
                    className: 'btn btn-primary',
                    onClick: () => { ref.current?.hideModal(); }
                }]}
                />
    )
}

export default React.forwardRef(ModalBookingFailed);
