import React, { useContext, useEffect, useState, useRef } from 'react';
import useTabiService from '@hooks/useTabiService';
import { GlobalContext } from '@contexts/Global';

import './receivePayment.scss';
import { useTracking } from '@hooks/useTracking';
import { partnerTypeByTripInfo } from '@helpers/Utils';
import { DEMO_CARD, DEMO_CARD_OVERRIDE, DEMO_CARD_OVERRIDE_CA, PartnerTypes } from '@helpers/Constants';
import RegularReceivePayment from './regularReceivePayment';
import TokenizedRecievePayment from './tokenizedRecievePayment';
import useParamsFromSessionStorage from '@hooks/useParamsFromSessionStorage';
import { Loading } from '@components';

export default function ReceivePayment() {
    const { globalData: { tripInfo: { localization: { language, currency, country }, ...tripInfo }, currentPartnerConfig, currentPartner } } = useContext(GlobalContext);

    const [card, setCard] = useState<any>(null);

    const [partnerType, setPartnerType] = useState(PartnerTypes.Travel);
    const tabiApi = useTabiService();
    const { trackCardMount, trackStepFinished, trackSubmit } = useTracking();
    const [{ order_id: orderIdFromUrl }] = useParamsFromSessionStorage();

    const lockGetCard: any = useRef();

    useEffect(() => {
        if (!trackCardMount) return;

        trackCardMount('complete-booking');
    }, [trackCardMount]);

    useEffect(() => {
        setPartnerType(
            partnerTypeByTripInfo({
                ...tripInfo,
                localization: {
                    language,
                    currency,
                    country
                }
            })
        )
    }, [tripInfo, language, currency, country])

    useEffect(() => {
        if (!tabiApi) return;
        if (!tripInfo?.order_amount) return;
        if (card) return;
        if (!orderIdFromUrl && !tripInfo.order_id) return;
        if (lockGetCard.current) return;
        if (!currentPartnerConfig) return;

        lockGetCard.current = true;
        const order_id = orderIdFromUrl || tripInfo.order_id

        const onGetCardResponse = ({ data: { wex } }: any) => {
            let wexToUse = wex;

            if (wex.card_number === DEMO_CARD) {
                wexToUse = (country === 'CA') ? DEMO_CARD_OVERRIDE_CA : DEMO_CARD_OVERRIDE
            }

            setCard(wexToUse);
            trackStepFinished('client-finished', tripInfo.order_amount, tripInfo);
            trackSubmit(order_id, wexToUse.card_token ? wexToUse.card_token : wexToUse.card_number, tripInfo);
        }

        if (currentPartnerConfig.isTokenizedPayment) {
            tabiApi.fetchToken(order_id)
                .then(onGetCardResponse)
                .finally(() => lockGetCard.current = false);
        }
        else {
            tabiApi.fetchCard(order_id)
                .then(onGetCardResponse)
                .finally(() => lockGetCard.current = false);

        }
    }, [tabiApi, card, setCard, tripInfo, trackStepFinished, trackSubmit, orderIdFromUrl, currentPartner, currentPartnerConfig, country]);

    if (!(card && currentPartnerConfig)) return <Loading bigLoading />
    if (currentPartnerConfig?.isTokenizedPayment) return <TokenizedRecievePayment partnerType={partnerType} card={card} />
    return <RegularReceivePayment partnerType={partnerType} card={card} />
}
