import { Modal } from '@components';
import React, { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next';
import "./loginModal.scss";

type LoginModalProps = {
    showFooter: boolean,
    title: string,
    content: any,
    onModalAcceptance: Function,
    closable: boolean,
    accpetanceText?: any,
    // Array of href we are going to insert into the terms content
    components: string[]
}

function LoginModal({ showFooter, title, content, components, onModalAcceptance, closable, accpetanceText }: LoginModalProps, ref: any) {
    const { t, i18n } = useTranslation(['login-modal']);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleModalAcceptance = useCallback(() => {
        if (!onModalAcceptance) return;

        onModalAcceptance();
        setIsLoading(true);

        }, [onModalAcceptance, setIsLoading])

    const safeComponents = (Array.isArray(components) ? components : components[i18n.language]).reduce((curr: any, url: string, i: number) => {
        if (url.includes("mailto:"))
            curr[`link${i}`] = <a href={url}> </a>;
        else
            curr[`link${i}`] = <a href={url} target="_blank" rel="noreferrer"> </a>;

        return curr
    }, {})

    const safeContent = typeof content === 'string' ? content : content[i18n.language];
    const safeTitle = typeof title === 'string' ? title : title[i18n.language];
    const safeAcceptanceText = accpetanceText?.[i18n.language] || t('acceptance-text');

    useEffect(() => {
        ref.current = {
            ...(ref?.current || {}),
            reset() {
                setTermsAccepted(false);
                setIsLoading(false);
            }
        }
    }, [setIsLoading, setTermsAccepted, ref])

  return (
    <Modal id="login-modal" ref={ref} className="login-modal" name="login-modal"
            neverClosable={!closable}
            headerOnTop={true}
            footerOnBottom={true}
            header={
                <div className="login-modal-header">
                    <h2 className="modal-title">
                        {safeTitle}
                    </h2>
                </div>
            }
            body={
                <div className={`modal-body-text`}>
                        <Trans components={safeComponents}>
                            {safeContent}
                        </Trans>
                </div>
            }
            footer={
                (showFooter &&
                    <>
                        <div id="divider"></div>
                        <div className="login-modal-footer modal-footer">
                            <div className="input-group form-check">
                                <input type={"checkbox"} id="accept-terms-id" checked={termsAccepted} onChange={() => setTermsAccepted(!termsAccepted)} className="form-check-input"/>
                                <label htmlFor="accept-terms-id" className="form-check-label">
                                    {safeAcceptanceText}
                                </label>
                            </div>
                            <button className={'btn btn-primary'} disabled={!termsAccepted  || isLoading} onClick={handleModalAcceptance}>
                                {t('common:continue')} { isLoading && <span className="spinner-border spinner-border-sm"></span> }
                            </button>
                        </div>
                    </> ) || <></>
        }>
    </Modal>
  )
}

export default React.forwardRef(LoginModal);