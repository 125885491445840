import React from 'react';
import { useTranslation } from 'react-i18next';
import { UpliftMisc } from '@icons/index';

import "./passwordChecker.scss";
import { PASSWORD_VALIDATIONS_REGEX } from '@helpers/CommonValidators';

interface RequirementConfig {
    requirmentText: string,
    requirementFunc: Function,
    pass: string
}

function PaswordRequirement ({ requirmentText, requirementFunc, pass}: RequirementConfig) {
    const veredic = requirementFunc(pass);
    return <div className='password-requirement'>
    <div className='veredict'>
        <UpliftMisc.CheckmarkIcon className={`validation-icon ${veredic ? "show" : "hide" }`}/>
    </div>
    <div className='requirement-text'>
        {requirmentText}
    </div>
</div>
}


export default function PasswordChecker({pass }: any) {
    const { t } = useTranslation(['auth', 'common', 'support']);

    const [
        hasNumber,
        hasUpperCase,
        hasLowerCase,
        hasRepeated
    ] = PASSWORD_VALIDATIONS_REGEX;

  return (
    <div className='pasword-requirements-container'>
        <PaswordRequirement
            requirmentText={t('inputs.pw.requirements.uppercase')}
            requirementFunc={(pass: string) => hasUpperCase.exec(pass) !== null}
            pass ={pass}
            />
        <PaswordRequirement
            requirmentText={t('inputs.pw.requirements.lowercase')}
            requirementFunc={(pass: string) => hasLowerCase.exec(pass)  !== null}
            pass ={pass}/>
        <PaswordRequirement
            requirmentText={t('inputs.pw.requirements.number')}
            requirementFunc={(pass: string) => hasNumber.exec(pass)  !== null}
            pass ={pass}/>
        <PaswordRequirement
            requirmentText={t('inputs.pw.invalid')}
            requirementFunc={(pass: string) => pass.length >= 8}
            pass ={pass}/>
        <PaswordRequirement
            requirmentText={t('inputs.pw.requirements.repeated')}
            requirementFunc={(pass: string) => !hasRepeated.exec(pass)}
            pass ={pass}/>
    </div>
  )
}
